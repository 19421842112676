import axios from 'axios';
import { handleErrorFromRequest } from '@services/handleErrorService.js';
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export async function updateExtensionSettings({ clinicId, patientSolicitation, patientSolicitationSurveyId, patientSolicitationSurveySchemaId }) {
  try {
    const res = await axios.put(
      `/clinics/${clinicId}/update_extension_settings`,
      {
        clinic_setting: {
          extension_patient_solicitation_survey_id: patientSolicitationSurveyId,
          extension_patient_solicitation_survey_schema_id: patientSolicitationSurveySchemaId,
          extension_patient_solicitation: patientSolicitation,
        },
      },
      { headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
    );
    return res;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}

export async function updateSftpSettings({ clinicId, patientSolicitation, patientSolicitationSurveyId, patientSolicitationSurveySchemaId }) {
  try {
    const res = await axios.put(
      `/clinics/${clinicId}/update_sftp_settings`,
      {
        clinic_setting: {
          sftp_patient_solicitation_survey_id: patientSolicitationSurveyId,
          sftp_patient_solicitation_survey_schema_id: patientSolicitationSurveySchemaId,
          sftp_patient_solicitation: patientSolicitation,
        },
      },
      { headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
    );
    return res;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}

export async function updateQrCodeSettings({ clinicId, patientSolicitation, patientSolicitationSurveyId, patientSolicitationSurveySchemaId }) {
  try {
    const res = await axios.put(
      `/clinics/${clinicId}/update_qr_code_settings`,
      {
        clinic_setting: {
          qr_code_patient_solicitation_survey_id: patientSolicitationSurveyId,
          qr_code_patient_solicitation_survey_schema_id: patientSolicitationSurveySchemaId,
          qr_code_patient_solicitation: patientSolicitation,
        },
      },
      { headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
    );
    return res;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}
